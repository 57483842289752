import * as msal from "@azure/msal-browser";

// MSAL configuration
export function getMsalInstance(): msal.PublicClientApplication {
  const config = {
    auth: {
      clientId: import.meta.env.VITE_AAD_CLIENT_ID,
      authority: `https://login.microsoftonline.com/${import.meta.env.VITE_AZURE_TENANT_ID}`,
      protocolMode: msal.ProtocolMode.AAD,
      redirectUri: "/",
      postLogoutRedirectUri: "/",
    },
    cache: {
      cacheLocation: msal.BrowserCacheLocation.SessionStorage,
    },
  };

  return new msal.PublicClientApplication(config);
}

// Export loginRequest for using in the application (e.g., for acquiring tokens)
export const loginRequest = {
  scopes: ["User.Read", "User.Read.All"], // You can modify the scopes here based on your needs
};
