import { DeleteIcon } from "@chakra-ui/icons";
import { Box, IconButton, Text } from "@chakra-ui/react";
import styles from "./ClearButton.module.css";

interface Props {
  className?: string;
  text: string;
  onClick: () => void;
  disabled?: boolean;
}

export const ClearButton = ({ className, text, disabled, onClick }: Props) => {
  return (
    <Box
      className={`${styles.container} ${className ?? ""} ${disabled && styles.disabled}`}
      onClick={onClick}
      display="flex"
      alignItems="center"
    >
      <IconButton
        variant="none"
        aria-label={text}
        icon={<DeleteIcon color="white" />}  // Set icon color to white
        isDisabled={disabled}  // Disable button if necessary
      />
      <Text color="white" ml={2}> {/* Set text color to white and add margin between icon and text */}
        {text}
      </Text>
    </Box>
  );
};
