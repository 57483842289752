import { useEffect, useState } from "react";
import styles from "./Answer.module.css";
import {
  Box,
  Flex,
  Text,
  IconButton,
  Link,
  Spacer,
  Icon,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";
import { FeedbackRequest, ChatTurn, MlApi, Conversation } from "../../api";
import { parseAnswerToHtml, HtmlParsedAnswer } from "./AnswerParser";
import { IoIosThumbsDown, IoIosThumbsUp } from "react-icons/io";
import ReactMarkdown from "react-markdown";
import ChakraUIRenderer from "chakra-ui-markdown-renderer";

interface Props {
  api: MlApi;
  question: string;
  answer: ChatTurn;
  history: ChatTurn[];
  isSelected?: boolean;
  onCitationClicked: (citation: string, index: number) => void;
  onThoughtProcessClicked?: () => void;
  onSupportingContentClicked: () => void;
  onFollowupQuestionClicked?: (question: string) => void;
  showFollowupQuestions?: boolean;
  conversation?: Conversation;
  disableButtons?: boolean;
  isWelcomeMessage?: boolean;
}

const enum FeedbackState {
  Default,
  Liked,
  Disliked,
}

export const Answer = ({
  api,
  question,
  answer,
  history,
  isSelected,
  onCitationClicked,
  onThoughtProcessClicked,
  onSupportingContentClicked,
  onFollowupQuestionClicked,
  showFollowupQuestions,
  conversation,
  disableButtons,
  isWelcomeMessage,
}: Props) => {
  const [parsedAnswer, setParsedAnswer] = useState<HtmlParsedAnswer>();
  const [rawAnswer, setRawAnswer] = useState<string>("");
  const [feedbackState, setFeedbackState] = useState<FeedbackState>(FeedbackState.Default);
  const [citations, setCitations] = useState<string[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure(); // Modal hook

  useEffect(() => {
    parseAnswerToHtml(api, answer.content, conversation).then((parsed) => {
      setParsedAnswer(parsed);
      setRawAnswer(parsed.answerHtml);
      setCitations(parsed.citations.map((x) => x.name));
    });
  }, [answer.content, conversation]);

  const saveFeedback = async (kind: number) => {
    const data: FeedbackRequest = {
      question: question,
      answer: rawAnswer,
      history: history,
      citations: citations,
      kind: kind,
    };
    await api.feedback(data);
  };

  const isLiked = (f: FeedbackState) => f === FeedbackState.Liked;
  const isDisliked = (f: FeedbackState) => f === FeedbackState.Disliked;
  const isDefault = (f: FeedbackState) => f === FeedbackState.Default;

  const onLike = async () => {
    setFeedbackState(FeedbackState.Liked);
    await saveFeedback(1);
  };

  const onDislike = async () => {
    setFeedbackState(FeedbackState.Disliked);
    await saveFeedback(-1);
  };

  const makeFeedbackButtons = () => (
    <Flex direction="row">
      <IconButton
        aria-label="Like answer"
        icon={<Icon as={IoIosThumbsUp} />}
        onClick={onLike}
        isDisabled={!isDefault(feedbackState)}
        color={isLiked(feedbackState) ? "green" : "default"}
      />
      <Box width="1em" />
      <IconButton
        aria-label="Dislike answer"
        icon={<Icon as={IoIosThumbsDown} />}
        onClick={onDislike}
        isDisabled={!isDefault(feedbackState)}
        color={isDisliked(feedbackState) ? "red" : "default"}
      />
    </Flex>
  );

  const feedbackButtons = makeFeedbackButtons();

  // Helper function to format the display name for the URLs
  const formatDisplayName = (url: string) => {
    const decodedUrl = decodeURIComponent(url); // Decode the URL
    const fileName = decodedUrl.split("/").pop() || "Unknown File"; // Extract the file name
    return fileName.split('?')[0].replace(/_/g, " "); // Remove query params and replace underscores with spaces
  };

  // Remove duplicate URLs
  const uniqueUrls = Array.from(new Set(answer.urls));

  // Even if there are no supporting content links (urls), render the answer text
  return (
    <Flex direction="column" className={`${styles.answerContainer} ${isSelected && styles.selected}`}>
      {!disableButtons && (
        <Flex>
          {feedbackButtons}
          <Spacer />
        </Flex>
      )}

      {/* Render the parsed answer as HTML */}
      <div className={styles.answerText} dangerouslySetInnerHTML={{ __html: rawAnswer }} />

      {/* Display supporting content (urls) as styled citation-like links with formatting */}
      {uniqueUrls.length > 0 && (
        <Flex wrap="wrap" gap={2} mt={4}>
          <Box width="100%" mt={4}>
            <Text className={styles.citationLearnMore}>Supporting Content Links:</Text>
          </Box>
          {uniqueUrls.map((url, i) => (
            <Link
              key={i}
              className={styles.citation}
              title={formatDisplayName(url)}
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              mx={2} // Horizontal margin between the links
            >
              {`${i + 1}. ${formatDisplayName(url)}`}
            </Link>
          ))}
        </Flex>
      )}

      {/* Ensure Thought Process button is only shown when there's valid content */}
      {!isWelcomeMessage && answer.cognitive_search_query && (
        <Box mt="4">
          <Button onClick={onOpen} colorScheme="teal" variant="solid">
            Check the Thought Process
          </Button>
        </Box>
      )}

      {/* Thought Process Modal */}
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Thought Process</ModalHeader>
          <ModalCloseButton />
          <ModalBody overflowY="auto">
            <Text mb="4" fontWeight="bold">
              Cognitive Search Query:
            </Text>
            <ReactMarkdown
              components={ChakraUIRenderer()}
              className={styles.answerText}
              children={answer.cognitive_search_query || "No thought process available"}
              skipHtml
            />
            <Text mt="4" mb="4" fontWeight="bold">
              Augmented Content:
            </Text>
            <ReactMarkdown
              components={ChakraUIRenderer()}
              className={styles.answerText}
              children={answer.augmented_content || "No augmented content available"}
              skipHtml
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} colorScheme="blue">
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};
