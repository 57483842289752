import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import ChatWithYourData from "./ChatWithYourData"; // Import ChatWithYourData

const Browser = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location

  useEffect(() => {
    if (location.pathname === "/cwyod") {
      // If the URL is /cwyod, render the ChatWithYourData component
      return;
    } else {
      // Automatically redirect to the desired chat page for any other route
      navigate("/ai/chat/0/e813c88dd93f48f08d30e715d3f1d888");
    }
  }, [navigate, location.pathname]);

  return location.pathname === "/cwyod" ? <ChatWithYourData /> : null; // Render ChatWithYourData for /cwyod
};

export default Browser;
