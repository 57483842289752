// src/pages/layout/Layout.tsx

import { Outlet, Link } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useUserContext } from "../../context/UserContext";
import logo from '/src/assets/logo.svg';

const loginRequest = {
  scopes: ["User.Read", "User.Read.All"],
};

const callMsGraph = async (
  accessToken: string
): Promise<{ displayName?: string; companyName?: string } | null> => {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  const apiUrl =
    "https://graph.microsoft.com/v1.0/me?$select=displayName,mail,jobTitle,companyName,officeLocation,employeeId,manager&$expand=manager";

  try {
    const response = await fetch(apiUrl, options);
    const data = await response.json();
    console.log("Graph API response with extended fields:", data);
    return data;
  } catch (error) {
    console.error("Error calling MS Graph API:", error);
    return null;
  }
};

const Layout = () => {
  const { instance, accounts } = useMsal();
  const { companyName, setCompanyName } = useUserContext();
  const [userData, setUserData] = useState<{ displayName?: string; companyName?: string } | null>(null);

  useEffect(() => {
    const fetchGraphData = async () => {
      try {
        const response = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        });
        const data = await callMsGraph(response.accessToken);
        setUserData(data);
        if (data?.companyName) {
          setCompanyName(data.companyName);
        }
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          try {
            const response = await instance.acquireTokenPopup({
              ...loginRequest,
              account: accounts[0],
            });
            const data = await callMsGraph(response.accessToken);
            setUserData(data);
            if (data?.companyName) {
              setCompanyName(data.companyName);
            }
          } catch (popupError) {
            console.error("Error during popup token acquisition:", popupError);
          }
        }
      }
    };

    fetchGraphData();
  }, [instance, accounts, setCompanyName]);

  const logout = () => {
    const logoutRequest = {
      account: instance.getActiveAccount(),
      mainWindowRedirectUri: `${import.meta.env.VITE_ROOT_URL}/login`,
      postLogoutRedirectUri: `${import.meta.env.VITE_ROOT_URL}/login`,
    };
    instance.logoutPopup(logoutRequest);
  };

  return (
    <Flex direction="column">
      <Flex
        as="header"
        bg="white"
        p="4"
        align="center"
        justify="space-between"
        width="100%"
        borderBottom="2px solid #2f5291"
      >
        <Flex
          direction="row"
          align="center"
          justify="space-between"
          maxW="1200px"
          width="100%"
          mx="auto"
        >
          <Link
            to="/"
            style={{
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img src={logo} alt="Logo" style={{ paddingRight: "10px" }} />
            <Text fontSize="lg" color="#2f5291">
              Hello,{" "}
              {userData ? (
                <>
                  {userData.displayName}{" "}
                  {userData.companyName ? `from ${userData.companyName}` : ""}
                </>
              ) : (
                accounts[0]?.username
              )}
            </Text>
          </Link>
          <Link to="/logout" onClick={logout} style={{ textDecoration: "none" }}>
            <Text fontSize="lg" color="#2f5291">
              Logout
            </Text>
          </Link>
        </Flex>
      </Flex>
      <Outlet />
    </Flex>
  );
};

export default Layout;
