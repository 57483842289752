import React from "react";
import ReactDOM from "react-dom/client";

import App from "./app";

import { ChakraProvider } from "@chakra-ui/react";
import { getMsalInstance } from "./auth";
import { MsalProvider } from "@azure/msal-react";


import "./index.css";

const instance = getMsalInstance();

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <MsalProvider instance={instance}>
      <ChakraProvider>
        <App />
      </ChakraProvider>
    </MsalProvider>
  </React.StrictMode>
);
