import { useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Layout from "./pages/layout/Layout";
import Chat from "./pages/chat/Chat";
import ChatWithDataModule from "./pages/chat/ChatWithDataModule";
import ChatWithYourData from "./pages/browser/ChatWithYourData";
import Browser from "./pages/browser/Browser";
import { BackendContext } from "./context";
import { ApiParameters } from "./api";
import {
  InteractionType,
  InteractionRequiredAuthError,
  AuthenticationResult,
} from "@azure/msal-browser";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { Spinner } from "@chakra-ui/react";
import { UserProvider } from "./context/UserContext";

import "./index.css";

const request = {
  scopes: [import.meta.env.VITE_AAD_BE_API],
};

const LoadingComponent = () => (
  <div className="spinner">
    <Spinner
      alignSelf="center"
      thickness="4px"
      speed="0.65s"
      emptyColor="gray.200"
      color="blue.500"
      size="xl"
    />
  </div>
);

const App = () => {
  const { instance, accounts } = useMsal();
  const api = new ApiParameters();

  const authRequest = request;

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      const account = accounts[0];

      const acquireToken = async (
        interactionType?: InteractionType
      ): Promise<AuthenticationResult | null> => {
        try {
          const response = await instance.acquireTokenSilent({
            scopes: [import.meta.env.VITE_AAD_BE_API],
            account,
          });
          return response;
        } catch (error) {
          if (error instanceof InteractionRequiredAuthError) {
            if (interactionType === InteractionType.Popup) {
              const response = await instance.acquireTokenPopup({
                scopes: [import.meta.env.VITE_AAD_BE_API],
                account,
              });
              return response;
            } else if (interactionType === InteractionType.Redirect) {
              await instance.acquireTokenRedirect({
                scopes: [import.meta.env.VITE_AAD_BE_API],
                account,
              });
              return null;
            } else {
              throw error;
            }
          } else {
            console.error("Token acquisition failed:", error);
            throw error;
          }
        }
      };

      api.setTokenAcquirer(acquireToken);
    }
  }, [accounts, instance, api]);

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Popup}
      authenticationRequest={authRequest}
      loadingComponent={LoadingComponent}
    >
      <UserProvider>
        <BackendContext.Provider value={api}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Layout />}>
                {/* Default page inside Layout */}
                <Route index element={<Browser />} />
                <Route path="ai/">
                  <Route path="chat" element={<Chat />} />
                  <Route path="chat/:partitionKey/:rowKey" element={<Chat />} />
                </Route>
                {/* RSI Routes */}
                <Route path="rsi/">
                  <Route path="chat" element={<Chat isRSI />} />
                  <Route
                    path="chat/:partitionKey/:rowKey"
                    element={<Chat isRSI />}
                  />
                </Route>
                {/* Chat With Your Data Routes */}
                <Route path="cwyod/">
                  <Route path="create" element={<ChatWithYourData />} />
                  <Route
                    path="chat/:partitionKey/:rowKey"
                    element={<ChatWithDataModule />}
                  />
                </Route>
              </Route>
            </Routes>
          </BrowserRouter>
        </BackendContext.Provider>
      </UserProvider>
    </MsalAuthenticationTemplate>
  );
};

export default App;
