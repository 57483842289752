import ReactMarkdown from "react-markdown";
import ChakraUIRenderer from "chakra-ui-markdown-renderer";

import styles from "./AnalysisPanel.module.css";

import { SupportingContent } from "../SupportingContent";
import { ChatTurn } from "../../api";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";

interface Props {
  question: ChatTurn;
  answer: ChatTurn;
  activeCitation: string | undefined;
  citationHeight: string;
  activeTab: number;
}

export const AnalysisPanel = ({ question, answer, activeCitation, citationHeight, activeTab }: Props) => {
  const cognitive_search_query = answer.cognitive_search_query || "Did not search for reference documents.";
  const augmented_content = question.augmented_content || "No augmented prompt available";
  
  return (
    <Tabs defaultIndex={activeTab}>
      <TabList>
        <Tab>Thought process</Tab>
        <Tab>Supporting content</Tab>
        {/* <Tab>Citation</Tab> */}
      </TabList>
      <TabPanels>
        <TabPanel>
          <ReactMarkdown components={ChakraUIRenderer()} className={styles.answerText} children={cognitive_search_query} skipHtml />
          <ReactMarkdown components={ChakraUIRenderer()} className={styles.answerText} children={augmented_content} skipHtml />
        </TabPanel>
        <TabPanel>
          <SupportingContent supportingContent={answer.data_points || []} urls={answer.urls || []} categories={answer.categories || []}/>
        </TabPanel>
        {/* <TabPanel>
          <iframe title="Citation" src={activeCitation} width="100%" height={citationHeight} />
        </TabPanel> */}
      </TabPanels>
    </Tabs>
  );
};
