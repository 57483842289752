type ParsedSupportingContentItem = {
    title: string;
    content: string;
};

export function parseSupportingContentItem(item: string): ParsedSupportingContentItem {
    // Assumes the item starts with the file name followed by : and the content.
    // Example: "sdp_corporate.pdf: this is the content that follows".
    const parts = item.split(")|>]:");
    const content = parts[0];
    const title_complete = content ? content.split('File name:')[1].split("\n")[0] : "";
    const title_parts = title_complete.split("/")
    const title = title_parts[title_parts.length - 1]

    return {
        title,
        content
    };
}
