import { parseSupportingContentItem } from "./SupportingContentParser";

import ReactMarkdown from "react-markdown";
import ChakraUIRenderer from "chakra-ui-markdown-renderer";

import styles from "./SupportingContent.module.css";
import { ListItem, Text, UnorderedList, Badge, Flex } from "@chakra-ui/react";

interface Props {
  supportingContent: string[];
  urls: string[];
  categories: string[];
}
const showTextContent = import.meta.env.VITE_SHOW_FULL_SUPPORTING_CONTENT_ANSWER === "true";

export const SupportingContent = ({ supportingContent, urls, categories }: Props) => {
  return (
    <UnorderedList className={styles.supportingContentNavList}>
      {supportingContent.map((x, i) => {
        const parsed = parseSupportingContentItem(x);
        // const badges = categories.map(x => {
        //   return { title: "sharepoint", color: "default" };
        // });
        return (
          <ListItem key={i} className={styles.supportingContentItem}>
            {/* <Flex justifyContent="flex-end">
              {badges.map(x => (
                <Badge fontSize="0.9rem" color={x.color} ml={2}>
                  {x.title}
                </Badge>
              ))}
            </Flex> */}
            <Text fontSize="1.3rem" fontWeight="600">
              Title:
            </Text>
            <Text mb={1}>{parsed?.title || "No title defined."}</Text>
            {urls[i] && (
              <>
                <Text fontSize="1.3rem" fontWeight="600">
                  Link:
                </Text>
                <a href={urls[i]} target="_blank" className={styles.source}>
                  {urls[i]}
                </a>
              </>
            )}
            {showTextContent && <ReactMarkdown components={ChakraUIRenderer()} children={parsed.content} skipHtml />}
          </ListItem>
        );
      })}
    </UnorderedList>
  );
};
