import { useState } from "react";
import { Flex, IconButton, Textarea } from "@chakra-ui/react";
import { FaPaperPlane } from "react-icons/fa";  

interface Props {
  onSend: (question: string) => void;
  disabled: boolean;
  placeholder?: string;
  clearOnSend?: boolean;
}

export const QuestionInput = ({ onSend, disabled, placeholder, clearOnSend }: Props) => {
  const [question, setQuestion] = useState<string>("");

  const sendQuestion = () => {
    if (disabled || !question.trim()) {
      return;
    }

    onSend(question);

    if (clearOnSend) {
      setQuestion("");
    }
  };

  const onQuestionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let value = e.target.value;
    setQuestion(value);
  };

  const captureEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      sendQuestion();
    }
  };

  const sendQuestionDisabled = disabled || !question.trim();

  return (
    <Flex direction="row" className="questionInputContainer"> 
      <Textarea
        isRequired
        value={question}
        onChange={onQuestionChange}
        placeholder={placeholder}
        onKeyUp={captureEnter}
        width="800px"
        border="2px solid white"
        color="white"
        _placeholder={{ color: "white" }}  
        _focus={{ borderColor: "white" }}  
      />
      <IconButton
        variant="none"
        aria-label="Send question button"
        icon={<FaPaperPlane color="white" />}  
        onClick={sendQuestion}
        isDisabled={sendQuestionDisabled}
      />
    </Flex>
  );
};
