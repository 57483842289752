import { Conversation, MlApi } from "../../api";

type Citation = {
  url: string;
  name: string;
  index: number;
};

export type HtmlParsedAnswer = {
  answerHtml: string;
  citations: Citation[];
  followupQuestions: string[];
};

export async function parseAnswerToHtml(api: MlApi, answer: string, conversation?: Conversation): Promise<HtmlParsedAnswer> {
  const citations: Citation[] = [];
  const followupQuestions: string[] = [];

  // Extract any follow-up questions that might be in the answer
  let parsedAnswer = answer.replace(/<<([^>>]+)>>/g, (_, content) => {
    followupQuestions.push(content);
    return "";
  });

  // trim any whitespace from the end of the answer after removing follow-up questions
  parsedAnswer = parsedAnswer.trim();

  const parts = parsedAnswer.split(/\[citation=([^\]]+)\]/g);

  const fragments: Promise<string>[] = parts.map(async (part, index) => {
    if (index % 2 === 0) {
      return part;
    } else {
      let citationIndex: number;

      const splitPart = part.split('/');
      const partUpdated = splitPart[splitPart.length - 1]
      const citation = await api.citation(partUpdated, conversation);
      const url = URL.createObjectURL(new Blob([citation], { type: "application/pdf" }));
      const existing = citations.find(c => c.name == partUpdated);

      if (existing != null) {
        citationIndex = citations.indexOf(existing) + 1;
      } else {
        citationIndex = citations.length + 1;
        citations.push({ url: url, name: partUpdated, index: citationIndex });
      }

      return `[${citationIndex}]`;
    }
  });
  const html = await Promise.all(fragments);

  return {
    answerHtml: html.join(""),
    citations,
    followupQuestions,
  };
}
