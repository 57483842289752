import { ChatRequest, FeedbackRequest, ConversationResponse, FormValues, OneConversationResponse, ChatResponse } from "./models";

import { useContext } from "react";
import { BackendContext } from "../context";
import { Conversation } from "./models";
import * as msal from "@azure/msal-browser";

type TokenAcquirer = (cbi?: msal.InteractionType | undefined, cbr?: msal.SilentRequest | undefined) => Promise<msal.AuthenticationResult | null>;

const enum Route {
  Conversation = "conversation",
  Chat = "chat",
  Feedback = "feedback",
}

export class ApiParameters {
  base: string;
  // @ts-ignore
  #tokenAcquirer: TokenAcquirer;

  constructor() {
    this.base = import.meta.env.VITE_API_ENDPOINT;
  }

  public setTokenAcquirer(fn: TokenAcquirer) {
    if (!fn) {
      throw Error(`Will not set token acquirer function to null`);
    }
    if (this.#tokenAcquirer != null) {
      throw Error(`Will not override set token acquirer function`);
    }
    this.#tokenAcquirer = fn;
  }

  public async acquireToken(): Promise<string> {
    if (!this.#tokenAcquirer) {
      throw Error("Token acquierer is null!");
    }

    const res = await this.#tokenAcquirer();

    if (!res) {
      throw Error(`Failed to get token: ${res}`);
    }

    return res.accessToken;
  }
}

export class ConversationApi {
  #parameters: ApiParameters;

  constructor() {
    const ps = useContext(BackendContext);

    if (!ps) {
      throw Error("MlApi received invalid backend context!");
    }

    this.#parameters = ps;
  }

  public async get(): Promise<ConversationResponse> {
    const token = await this.#parameters.acquireToken();
    const url = `${this.#parameters.base}/${Route.Conversation}/`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const conversations = await response.json();

    if (response.status > 299 || !response.ok) {
      throw Error(conversations.error || "Unknown error");
    }

    if (!Array.isArray(conversations)) {
      throw Error("Expected respose array, received: " + conversations);
    }

    return { status: 200, conversations };
  }

  public async getOne(partitionKey: string, rowKey: string): Promise<OneConversationResponse> {
    const token = await this.#parameters.acquireToken();
    const url = `${this.#parameters.base}/${Route.Conversation}/${partitionKey}/${rowKey}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const conversation = await response.json();

    if (response.status > 299 || !response.ok) {
      throw Error(conversation.error || "Unknown error");
    }

    return { status: 200, conversation };
  }

  public async create(form: FormValues): Promise<Conversation> {
    const data = new FormData();

    data.append("description", form.description);
    data.append("name", form.name);
    data.append("language", form.language);
    Array.from(form.files).forEach(file => {
      data.append("files", file);
    });

    const token = await this.#parameters.acquireToken();
    const url = `${this.#parameters.base}/${Route.Conversation}/`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: data,
    });

    const parsedResponse = await response.json();

    if (response.status > 299 || !response.ok) {
      throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
  }

  public async delete(entity: Conversation) {
    const token = await this.#parameters.acquireToken();
    const url = `${this.#parameters.base}/${Route.Conversation}/`;
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(entity),
    });

    const parsedResponse = await response.json();

    if (response.status > 299 || !response.ok) {
      throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
  }
}

export class MlApi {
  #parameters: ApiParameters;
  #conversation: Conversation;

  constructor(entity: Conversation) {
    const ps = useContext(BackendContext);

    if (!ps) {
      throw Error("MlApi received invalid backend context!");
    }

    this.#parameters = ps;
    this.#conversation = entity;
  }

  public async chat(options: ChatRequest, conversation?: Conversation): Promise<ChatResponse> {
    const token = await this.#parameters.acquireToken();
    const url = `${this.#parameters.base}/${Route.Chat}/`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        conversation: conversation || this.#conversation,
        language: "en-us",
        message: options.message,
        history: options.history,
        approach: options.approach,
        overrides: {
          semantic_ranker: options.overrides?.semanticRanker,
          semantic_captions: options.overrides?.semanticCaptions,
          top: options.overrides?.top,
          temperature: options.overrides?.temperature,
          prompt_template: options.overrides?.promptTemplate,
          prompt_template_prefix: options.overrides?.promptTemplatePrefix,
          prompt_template_suffix: options.overrides?.promptTemplateSuffix,
          cognitive_filters: options.overrides?.cognitiveFilters,
          suggest_followup_questions: options.overrides?.suggestFollowupQuestions,
        },
      }),
    });

    const parsedResponse: ChatResponse = await response.json();
    if (response.status > 299 || !response.ok) {
      throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
  }

  public async feedback(payload: FeedbackRequest): Promise<void> {
    const token = await this.#parameters.acquireToken();
    const url = `${this.#parameters.base}/${Route.Feedback}/`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });

    const body = await response.json();

    if (response.status > 299 || !response.ok) {
      throw Error(body || "Unknown error");
    }

    return body;
  }

  public getCitationFilePath(citation: string, conversation?: Conversation): string {
    const url = `${this.#parameters.base}/${Route.Conversation}`;
    return `${url}/content/${conversation?.Container || this.#conversation.Container}/${citation}`;
  }

  public async citation(citation: string, conversation?: Conversation): Promise<Blob> {
    const token = await this.#parameters.acquireToken();
    const url = this.getCitationFilePath(citation, conversation);
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.body) {
      throw Error(`Failed to get citation: ${response}`);
    }

    return await response.blob();
  }
}