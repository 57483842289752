import { Box, Button, Flex, IconButton, Text } from "@chakra-ui/react";
import { WarningIcon } from "@chakra-ui/icons";

import styles from "./Answer.module.css";

interface Props {
  error: string;
  onRetry: () => void;
}

export const AnswerError = ({ error, onRetry }: Props) => {
  return (
    <Flex direction="column" className={styles.answerLoadingContainer}>
      <Box>
        <IconButton aria-label="Error icon" icon={<WarningIcon color="red.500" />} />
        <Text className={styles.answerText}>{error}</Text>
      </Box>
      <Box w="1em" />
      <Box>
        <Button className={styles.retryButton} onClick={onRetry}>
          Retry
        </Button>
      </Box>
    </Flex>
  );
};
