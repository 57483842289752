export enum Approaches {
  ReadRetrieveRead = "rrr",
}

export type ChatRequestOverrides = {
  semanticRanker?: boolean;
  semanticCaptions?: boolean;
  cognitiveFilters?: CognitiveFilter;
  top?: number;
  temperature?: number;
  promptTemplate?: string;
  promptTemplatePrefix?: string;
  promptTemplateSuffix?: string;
  suggestFollowupQuestions?: boolean;
};

export type ChatRequest = {
  message: string;
  history: ChatTurn[];
  approach: Approaches;
  overrides?: ChatRequestOverrides;
};

export type ChatResponse = {
  answer: string;
  data_points: string[];
  cognitive_search_query: string;
  user_augmented_message: string;
  error?: string;
  urls?: string[];
  categories?: string[];
};

// keeps track of history of chats
export type ChatTurn = {
  role: string;
  content: string;
  data_points?: string[];
  cognitive_search_query?: string;
  augmented_content?: string;
  urls?: string[];
  categories?: string[];
};

export type CognitiveFilter = {
  category: string[];
};

// serialized entry in the db
export type Conversation = {
  Container: string;
  Index: string;
  Name: string;
  Description: string;
  Language: string;
  PartitionKey: number;
  RowKey: string;
};

export type ConversationResponse = {
  status?: number;
  conversations: Conversation[];
};

export type OneConversationResponse = {
  status?: number;
  conversation?: Conversation;
};

// conversation form creation
export type FormValues = {
  name: string;
  files: FileList;
  description: string;
  language: string;
};

// thumbs up / down
export type FeedbackRequest = {
  question: string;
  answer: string;
  history: ChatTurn[];
  citations: string[];
  kind: number;
};
