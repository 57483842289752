import {
  Flex,
  Box,
  Text,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  FormControlProps,
  Heading,
  Icon,
} from "@chakra-ui/react";
import { FaFileUpload } from "react-icons/fa";
import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ConversationApi } from "../../api";
import "./Browser.css";

const ChatWithYourData = () => {
  const api = new ConversationApi();
  const [formError, setFormError] = useState<string>("");
  const [formErrorProps, setFormErrorProps] = useState<FormControlProps>({
    isDisabled: true,
    isInvalid: false,
  });

  const {
    register: formRegister,
    handleSubmit: formHandleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<{ files: FileList }>();
  const navigate = useNavigate();

  const submitForm: SubmitHandler<{ files: FileList }> = async (data) => {
    try {
      const generatedName = `conversation-${Date.now()}`.toLowerCase();
      const generatedDescription = "ILF CWYOD";
      const defaultLanguage = "en-us";

      const newData = {
        name: generatedName,
        description: generatedDescription,
        language: defaultLanguage,
        files: data.files,
      };

      const conversation = await api.create(newData);

      navigate(
        `/cwyod/chat/${conversation.PartitionKey}/${conversation.RowKey}`,
        { state: { conversation } }
      );
    } catch (err) {
      if (err instanceof Error) {
        showFormError(err);
      } else {
        throw new Error(
          "Expected error from createConversation to be of type Error"
        );
      }
    }
  };

  const showFormError = (err: Error) => {
    setFormError(err.message);
    setFormErrorProps({ isDisabled: false, isInvalid: true });
  };

  const hideFormError = () => {
    setFormError("");
    setFormErrorProps({ isDisabled: true, isInvalid: false });
  };

  return (
    <Flex justify="center" py="6" px="4" bg="gray.50" minH="100vh">
      <Flex
        direction="column"
        minW="30em"
        maxW="40em"
        bg="white"
        p="6"
        rounded="lg"
        boxShadow="md"
      >
        <Flex justify="center" align="center" mb="6">
          <Icon as={FaFileUpload} w="12" h="12" color="blue.500" />
        </Flex>
        <Heading size="lg" textAlign="center" mb="2" color="gray.700">
          Upload Your File
        </Heading>
        <Text textAlign="center" color="gray.500" mb="6">
          Start by uploading your data file to ask the questions.
        </Text>

        <form onSubmit={formHandleSubmit(submitForm)} className="form">
          <FormControl isRequired isInvalid={errors.files != null} mb="4">
            <FormLabel fontWeight="bold" color="gray.600">
              Choose Files
            </FormLabel>
            <Input
              {...formRegister("files")}
              type="file"
              multiple
              variant="filled"
              bg="gray.100"
              borderColor="gray.300"
              focusBorderColor="blue.500"
              _hover={{ bg: "gray.200" }}
              py="2"
              px="3"
            />
            <FormErrorMessage>Files are required</FormErrorMessage>
          </FormControl>

          <Button
            mt="4"
            colorScheme="blue"
            isLoading={isSubmitting}
            type="submit"
            size="lg"
            width="full"
            fontWeight="bold"
          >
            Submit
          </Button>

          {formError && (
            <Box mt="4" p="3" bg="red.50" border="1px" borderColor="red.200">
              <Text color="red.500" fontSize="sm" textAlign="center">
                {formError}
              </Text>
            </Box>
          )}
        </form>
      </Flex>
    </Flex>
  );
};

export default ChatWithYourData;
