import { useMsal, useMsalAuthentication, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../../auth"; // Assuming loginRequest is defined in auth.ts

import React, { useRef, useState, useEffect } from "react";
import {
  Flex,
  Box,
  Drawer,
  useDisclosure,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  Input,
  Textarea,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Checkbox,
  SliderMark,
  CheckboxGroup,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import ReactMarkdown from "react-markdown";
import ChakraUIRenderer from "chakra-ui-markdown-renderer";
import styles from "./Chat.module.css";
import {
  MlApi,
  Approaches,
  ChatRequest,
  ChatTurn,
  ConversationApi,
  OneConversationResponse,
} from "../../api";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { UserChatMessage } from "../../components/UserChatMessage";
import {
  AnalysisPanel,
  AnalysisPanelTabs,
} from "../../components/AnalysisPanel";
import { ClearButton } from "../../components/ClearButton";
import { useLocation, useParams } from "react-router-dom";
import { useUserContext } from "../../context/UserContext"; // Import the context

const categories = [{ id: "sharepoint", name: "Sharepoint" }];



export interface Category {
  id: string;
  name: string;
}
interface CategoriesList {
  category: Category[];
}

const ChatWithDataModule = () => {
const { login } = useMsalAuthentication(InteractionType.Popup, loginRequest); // Hook for triggering login
const { accounts } = useMsal(); // MSAL accounts

useEffect(() => {
  if (accounts.length === 0) {
    // Trigger login automatically if no account is found
    login();
  }
}, [accounts, login]);

  const { companyName } = useUserContext();
  const { state } = useLocation();
  const { partitionKey, rowKey } = useParams();
  const { conversation } = state || {};
  const api = new MlApi(conversation);
  const conversationApi = new ConversationApi();
    /*
  const partitionKey2 = "0";
  const rowKey2 = "e813c88dd93f48f08d30e715d3f1d888"; //IMS
  const api2 = new MlApi(conversation);
  const conversationApi2 = new ConversationApi();

  const partitionKey3 = "0";
  const rowKey3 = "cfcfe4fad3044d6e8de0a32d82cf9be3"; //Knowledge portal
  const api3 = new MlApi(conversation);
  const conversationApi3 = new ConversationApi();
*/
  const {
    isOpen: isConfigPanelOpen,
    onOpen: onConfigPanelOpen,
    onClose: onConfigPanelClose,
  } = useDisclosure();
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();

  const [promptTemplate, setPromptTemplate] = useState<string>("");
  const [retrieveCount, setRetrieveCount] = useState<number>(3);
  const [useSemanticRanker, setUseSemanticRanker] = useState<boolean>(true);
  const [useSemanticCaptions, setUseSemanticCaptions] = useState<boolean>(false);
  const [selectedCategories, setSelectedCategories] = useState<CategoriesList>({
    category: categories,
  });
  const [useSuggestFollowupQuestions, setUseSuggestFollowupQuestions] =
    useState<boolean>(false);
  const [oneConversation, setOneConversation] =
    useState<OneConversationResponse>({ status: 0 });
  const [oneConversation2, setOneConversation2] =
    useState<OneConversationResponse>({ status: 0 });

  const lastQuestionRef = useRef<string>("");
  const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const [activeCitation, setActiveCitation] = useState<string>("");
  const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] =
    useState<AnalysisPanelTabs>(AnalysisPanelTabs.Closed);

  const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
  const [history, setHistory] = useState<ChatTurn[]>([]);

  // Welcome message content

useEffect(() => {
  const fetchData = async () => {
    if (!companyName) {
      console.log("Company Name not yet available");
      return;  // Exit if companyName is not yet available
    }
/*
      // Update rowKey based on companyName
      if (companyName.toLowerCase().includes("germany")) {
        setRowKey("07d57303c55c4700a5e163ae464eb134");
      } else if (companyName.toLowerCase().includes("uae")) {
        setRowKey("20fc24cbba0e49a7aa52cea513c66836");
      } else if (companyName.toLowerCase().includes("poland")) {
        setRowKey("765160dc3a3945de84b40ec08cd75a00");
      } else if (companyName.toLowerCase().includes("austria")) {
        setRowKey("faa9fe76591647549fa5b3d3c166d257");
      } 
*/
    try {
      console.log("Company Name:", companyName);  // Logs actual company name
      console.log("RowKey Set:", rowKey);  // Logs the set rowKey
      const r = await conversationApi.getOne(partitionKey || "", rowKey || "");
      setOneConversation(r);
    } catch (error) {
      console.error("Error fetching conversation:", error);
    }
  };

  fetchData();  // Call the function inside the effect
}, [companyName, partitionKey, rowKey]);  // Added rowKey to the dependency array

const handleQuestionSubmit = async (question: string) => {
  lastQuestionRef.current = question;

  // Add the user question to the history right away
  const lastUserMessage: ChatTurn = {
    role: "user",
    content: question,
    augmented_content: "",
  };

  setHistory((prevHistory) => [...prevHistory, lastUserMessage]);

  let validAnswerCount = 0; // Counter for valid answers
  console.log("Start: ", validAnswerCount);

  // First API call with the initial conversation
  const firstApiResult = await makeApiRequest(question, "Source:Uploaded File", oneConversation, api);
  if (firstApiResult) {
    validAnswerCount++;
    console.log("1st: ", validAnswerCount);

  }
  /*
   // Repeat for the 2nd conversation
   const secondConversation = await conversationApi2.getOne(partitionKey2, rowKey2);
   setOneConversation2(secondConversation);
   const secondApiResult = await makeApiRequest(question, "Source: IMS Portal", secondConversation, api2);
   if (secondApiResult) {
     validAnswerCount++;
     console.log("2nd: ", validAnswerCount);
 
   }


  // Repeat for the third conversation
  const thirdConversation = await conversationApi3.getOne(partitionKey3, rowKey3);
  setOneConversation2(thirdConversation);
  const thirdApiResult = await makeApiRequest(question, "Source: Knowledge Portal", thirdConversation, api3);
  if (thirdApiResult) {
    validAnswerCount++;
    console.log("3rd: ", validAnswerCount);

  }


  */
  // If no valid answer was received from any API call, add a "no answer" message as a bubble
  if (validAnswerCount === 0) {
    const noAnswerMessage: ChatTurn = {
      role: "assistant",
      content:
        "We couldn't find an answer to your question based on the current knowledge sources. Please try asking another question.",
      data_points: [],
      cognitive_search_query: "",
      urls: [],
      categories: [],
      augmented_content: "",
    };
    setHistory((prevHistory) => [...prevHistory, noAnswerMessage]);
  }
};

const makeApiRequest = async (
  question: string,
  header: string,
  conversationData: OneConversationResponse,
  apiInstance: MlApi,
  hidden: string = ""
) => {
  setIsLoading(true);
  setActiveCitation("");
  setActiveAnalysisPanelTab(AnalysisPanelTabs.Closed);

  try {
    const request: ChatRequest = {
      message: question + hidden,
      history: history,
      approach: Approaches.ReadRetrieveRead,
      overrides: {
        promptTemplate: promptTemplate.length === 0 ? undefined : promptTemplate,
        top: retrieveCount,
        semanticRanker: useSemanticRanker,
        semanticCaptions: useSemanticCaptions,
        suggestFollowupQuestions: useSuggestFollowupQuestions,
      },
    };

    const result = await apiInstance.chat(request, conversationData.conversation);
    if (result.error) {
      setError(result.error);
      return false; // No valid answer
    } else if (result.answer && result.urls && result.urls.length > 0) {
      const lastAssistantMessage: ChatTurn = {
        role: "assistant",
        content: header + "\n" + result.answer,
        data_points: result.data_points,
        cognitive_search_query: result.cognitive_search_query,
        urls: result.urls,
        categories: result.categories,
        augmented_content: result.user_augmented_message,
      };
      setHistory((prevHistory) => [...prevHistory, lastAssistantMessage]);
      return true;  // Indicates a valid answer was returned
    }
    return false;  // Indicates no valid answer was returned
  } catch (e) {
    let err = "Unknown error..";
    if (e instanceof Error) err = e.message;
    setError(err);
    return false;
  } finally {
    setIsLoading(false);
  }
};

const clearChat = () => {
  lastQuestionRef.current = "";
  setError("");
  setActiveCitation("");
  setActiveAnalysisPanelTab(AnalysisPanelTabs.Closed);
  setHistory([]); // Clear the chat history
};

useEffect(
  () => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }),
  [isLoading, history]
);

const onPromptTemplateChange = (
  _ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
  newValue?: string
) => {
  setPromptTemplate(newValue || "");
};

const onRetrieveCountChange = (value?: number) => {
  setRetrieveCount(value || 3);
};

const onUseSemanticRankerChange = (
  _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
  checked?: boolean
) => {
  setUseSemanticRanker(!!checked);
};

const onUseSemanticCaptionsChange = (
  _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
  checked?: boolean
) => {
  setUseSemanticCaptions(!!checked);
};

const onCategoryChange = (newValue?: Category[]) => {
  setSelectedCategories({ category: newValue || [] });
};

const onUseSuggestFollowupQuestionsChange = (
  _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
  checked?: boolean
) => {
  setUseSuggestFollowupQuestions(!!checked);
};

const onExampleClicked = (example: string) => {
  makeApiRequest(example, "Example", oneConversation, api);
};

const onShowCitation = (citation: string, index: number) => {
  if (
    activeCitation === citation &&
    activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab &&
    selectedAnswer === index
  ) {
    setActiveAnalysisPanelTab(AnalysisPanelTabs.Closed);
  } else {
    setActiveCitation(citation);
    setActiveAnalysisPanelTab(AnalysisPanelTabs.CitationTab);
  }

  setSelectedAnswer(index);
};

const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
  if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
    setActiveAnalysisPanelTab(AnalysisPanelTabs.Closed);
  } else {
    setActiveAnalysisPanelTab(tab);
  }

  setSelectedAnswer(index);

  // Open the modal after displaying the thought process in the panel
  if (tab === AnalysisPanelTabs.ThoughtProcessTab) {
    onModalOpen(); // This will open the modal after the tab is selected
  }
};

const showError: () => string = () => {
  if (!error) {
    return "No error";
  }

  if ("error" in Object.keys(error)) {
    // @ts-ignore
    const msg: string = error.error.message;
    return msg;
  }

  return error.toString();
};

if (!conversation && oneConversation.status === 0) {
  return (
    <Spinner
      alignSelf="center"
      thickness="4px"
      speed="0.65s"
      emptyColor="gray.200"
      color="blue.500"
      size="xl"
    />
  );
  
}
 const welcomeMessage = `Welcome to the ILF Spark Bot! Please ask question relevant to the uploaded file, and I'll do my best to assist. Keep in mind that the answers are AI-generated, so ensure to check the answer quality based on the referenced content.`;
return (
  <Flex justify="center" className={styles.container} direction="column">

    {/* Show chat UI if the user is authenticated */}
    <AuthenticatedTemplate>
      <Flex direction="row" justify="center" className={styles.chatContainer}>
        <Flex direction="column">
          <Box className={styles.chat}>
            
            {/* Display the welcome message directly here in a styled bubble */}
            <Box className={styles.welcomeBubble}  mb={4}>
              <ReactMarkdown components={ChakraUIRenderer()} children={welcomeMessage} />
            </Box>

            {history.map((message, index) => (
              <Box key={index}>
                {message.role === "user" ? (
                  <UserChatMessage message={message.content} />
                ) : (
                  <Box className={styles.gptAnswer}>
                    <Answer
                      api={api}
                      key={index}
                      question={lastQuestionRef.current}
                      answer={message}
                      history={history}
                      isSelected={
                        selectedAnswer === index &&
                        activeAnalysisPanelTab !== AnalysisPanelTabs.Closed
                      }
                      onCitationClicked={(c) => onShowCitation(c, index)}
                      onThoughtProcessClicked={() =>
                        onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)
                      }
                      onSupportingContentClicked={() =>
                        onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)
                      }
                      onFollowupQuestionClicked={(q) =>
                        makeApiRequest(q, "Follow up", oneConversation, api)
                      }
                      showFollowupQuestions={
                        useSuggestFollowupQuestions && history.length - 1 === index
                      }
                      conversation={oneConversation.conversation}
                    />
                  </Box>
                )}
              </Box>
            ))}

            {isLoading && (
              <Box>
                <AnswerLoading />
              </Box>
            )}
            {error ? (
              <Box>
                <UserChatMessage message={lastQuestionRef.current} />
                <AnswerError
                  error={showError()}
                  onRetry={() => makeApiRequest(lastQuestionRef.current, "Ref", oneConversation, api)}
                />
              </Box>
            ) : null}
            <div ref={chatMessageStreamEnd} />
          </Box>
        </Flex>

        {history.length > 0 &&
          activeAnalysisPanelTab !== AnalysisPanelTabs.Closed && (
            <Flex>
              <AnalysisPanel
                question={history[selectedAnswer - 1]}
                answer={history[selectedAnswer]}
                activeCitation={activeCitation}
                citationHeight="810px"
                activeTab={activeAnalysisPanelTab}
              />
            </Flex>
          )}

        <Drawer isOpen={isConfigPanelOpen} onClose={onConfigPanelClose}>
          <DrawerOverlay />
          <DrawerContent>
            <Box h="2em" />
            <DrawerHeader>Configure answer generation</DrawerHeader>
            <DrawerBody>
              <Input placeholder="Type here..." />

              <Box h="2em" />
              <Textarea
                defaultValue={promptTemplate}
                placeholder="Override prompt template"
                onChange={onPromptTemplateChange}
              />
              <Box h="3em" />

              <Slider
                mb="2em"
                aria-label="slider-ex-1"
                min={1}
                max={50}
                defaultValue={retrieveCount}
                onChange={onRetrieveCountChange}
              >
                <SliderMark
                  value={retrieveCount}
                  textAlign="center"
                  bg="blue.500"
                  color="white"
                  mt="-10"
                  ml="-5"
                  w="12"
                >
                  {retrieveCount}
                </SliderMark>
                <SliderMark value={5} mt="5px" fontSize="sm">
                  Amount of documents to retrieve
                </SliderMark>
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>

              <CheckboxGroup>
                <Checkbox onChange={onUseSemanticRankerChange}>
                  Use semantic ranker for retrieval
                </Checkbox>
                <Checkbox
                  mt="1em"
                  onChange={onUseSemanticCaptionsChange}
                  isDisabled={!useSemanticRanker}
                >
                  Use query-contextual summaries instead of whole documents
                </Checkbox>
                <Checkbox mt="1em" onChange={onUseSuggestFollowupQuestionsChange}>
                  Suggest follow-up questions
                </Checkbox>
              </CheckboxGroup>
            </DrawerBody>
            <DrawerCloseButton />
          </DrawerContent>
        </Drawer>

        {/* Modal for displaying the full thought process */}
        <Modal isOpen={isModalOpen} onClose={onModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Complete Thought Process</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <ReactMarkdown
                components={ChakraUIRenderer()}
                children={history[selectedAnswer]?.cognitive_search_query || ""}
                skipHtml
              />
              <ReactMarkdown
                components={ChakraUIRenderer()}
                children={history[selectedAnswer]?.augmented_content || ""}
                skipHtml
              />
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onModalClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>

      <Flex className={styles.questionInputContainer} bg="#2f5291">
        <QuestionInput
          clearOnSend
          placeholder="Type in a question..."
          disabled={isLoading}
          onSend={(question) => handleQuestionSubmit(question)}
        />
        <ClearButton
          text="Clear chat"
          className={styles.clearChatButton}
          onClick={clearChat}
          disabled={!lastQuestionRef.current || isLoading}
        />
      </Flex>
    </AuthenticatedTemplate>
  </Flex>
);

};

export default ChatWithDataModule;
