import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { AnswerIcon } from "./AnswerIcon";
import styles from "./Answer.module.css";

export const AnswerLoading = () => (
  <Flex className={styles.answerLoadingContainer}>
    <AnswerIcon />
    <Box w="2em" />
    <Spinner size="xl" />
    <Box w="2em" />
    <Text>Generating answer...</Text>
  </Flex>
);
