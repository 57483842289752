// UserContext.tsx

import React, { createContext, useContext, useState, ReactNode } from "react";

interface UserContextType {
  companyName: string | null;
  setCompanyName: (companyName: string) => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [companyName, setCompanyName] = useState<string | null>(null);

  return (
    <UserContext.Provider value={{ companyName, setCompanyName }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
};
